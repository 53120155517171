import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Form, Select, Modal } from 'antd';
import { Store } from 'antd/lib/form/interface';
import DateOnlyPicker from '../../../common/DateOnlyPicker';
import { patchJson, API_URL, showError } from '../../../../../helpers/api/api';
import { Application } from '../../../../../types/application-types';

interface Props {
  visible: boolean;
  projectId: string;
  application: Application;
  onCancel: () => void;
  onUpdateComplete: () => void;
}

const RejectModal: FC<Props> = ({
  visible,
  projectId,
  application,
  onCancel,
  onUpdateComplete,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { Option, OptGroup } = Select;
  const rejectOptions = [
    {
      text: 'ข้อมูลประวัติการชำระหนี้/ภาระหนี้',
      objects: [
        {
          value: 'A01',
          text: 'ประวัติเครดิตต่ำการเกณฑ์ที่กำหนด',
        },
        {
          value: 'A02',
          text: 'ถือบัตรเกินจำนวนที่กำหนด (มากกว่า 3 สถาบัน)',
        },
        {
          value: 'A03',
          text: 'ภาระหนี้สูง',
        },
        {
          value: 'A04',
          text: 'ไม่พบข้อมูลอ้างอิงใน NCB และเงินหมุนเวียนในบัญชีคงเหลือต่ำ',
        },
        {
          value: 'A05',
          text: 'เพิ่งได้รับอนุมัติสินเชื่อใหม่จากสถาบันอื่น',
        },
        {
          value: 'A06',
          text: 'ประวัติการขอสินเชื่อใหม่ย้อนหลัง 3 เดือน มีประวัติขอมากกว่า 5 สถาบัน',
        },
        {
          value: 'A07',
          text: 'Credit Scoring ต่ำกว่าเกณฑ์ที่กำหนด',
        },
      ],
    },
    {
      text: 'ข้อมูลส่วนบุคคล',
      objects: [
        {
          value: 'B01',
          text: 'อายุต่ำกว่าหรือสูงกว่าเกณฑ์ที่กำหนด',
        },
        {
          value: 'B02',
          text: 'ผู้สมัครไม่ใช่ผู้มีสัญชาติไทย',
        },
      ],
    },
    {
      text: 'ข้อมูลการทำงาน',
      objects: [
        {
          value: 'D01',
          text: 'อายุงานต่ำกว่าเกณฑ์ที่กำหนด (6 เดือนขึ้นไป)',
        },
        {
          value: 'D02',
          text: 'เปิดกิจการต่ำกว่าเกณฑ์ที่กำหนด (1 ปีขึ้นไป)',
        },
        {
          value: 'D03',
          text: 'ลูกค้าลาออกจากงาน/ไม่ได้ทำงาน',
        },
        {
          value: 'D04',
          text: 'อาชีพเสี่ยง หน้าที่การงานไม่มั่นคง',
        },
      ],
    },
    {
      text: 'ข้อมูลรายได้',
      objects: [
        {
          value: 'E01',
          text: 'รายได้ต่ำกว่าเกณฑ์ที่กำหนด',
        },
        {
          value: 'E02',
          text: 'รายได้ไม่ตรงกับสลิปเงินเดือน/หนังสือรับรองรายได้',
        },
      ],
    },
    {
      text: 'ข้อมูลการติดต่อลูกค้า/ที่ทำงาน',
      objects: [
        {
          value: 'F01',
          text: 'ติดต่อที่ทำงานไม่ได้/เช็คข้อมูลกับฝ่ายบุคคลไม่ได้',
        },
        {
          value: 'F02',
          text: 'ไม่สามารถติดต่อผู้สมัครได้/ไม่ติดต่อกลับภายในระยะเวลาที่กำหนด',
        },
        {
          value: 'F03',
          text: 'ลูกค้าขอยกเลิกกการสมัคร',
        },
      ],
    },
    {
      text: 'เอกสารใบสมัคร/เอกสารประกอบการสมัคร',
      objects: [
        {
          value: 'G01',
          text: 'ไม่ได้ลงลายมือชื่อในใบสมัคร/ลายมือชื่อไม่ตรงกัน',
        },
        {
          value: 'G02',
          text: 'เอกสารน่าสงสัย/ยังไม่ยืนยันการปลอมแปลง',
        },
        {
          value: 'G03',
          text: 'เอกสารไม่สมบูรณ์/เอกสารเพิ่มเติมไม่ส่งมาภายในระยะเวลาที่กำหนด',
        },
        {
          value: 'G04',
          text: 'ติด Blacklist ของบริษัทฯ',
        },

        {
          value: 'T01',
          text: 'ไม่ผ่านเกณฑ์การพิจารณาเบื้องต้น',
        },
      ],
    },
  ];
  const onFinish = async (value: Store) => {
    try {
      await patchJson(`${API_URL}/projects/${projectId}/applications/${application.id}/status`, {
        status: 'reject',
        rejectReason: value.rejectReason,
        rejectDate: value.rejectDate,
      });
      onUpdateComplete();
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label={intl.formatMessage({ id: 'rejectDate' })}
          name="rejectDate"
          rules={[{ required: true }]}
        >
          <DateOnlyPicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'rejectReason' })} name="rejectReason">
          <Select placeholder="Please select status">
            {rejectOptions.map(header => (
              <OptGroup label={header.text} key={header.text}>
                {header.objects.map(object => (
                  <Option value={object.value} key={object.value}>
                    {object.value}: {object.text}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RejectModal;
