import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import { satangToBath } from '../../../../../../../helpers/format/satangToBath';
import { iso8601ToLocalDate } from '../../../../../../../helpers/date/date';

const DebtSummary: React.FC<any> = (props: any) => {
  const { dataSource, loading } = props;

  const columns: any = [
    {
      title: <FormattedMessage id="loan.id" />,
      key: 'id',
      fixed: 'left',
      render: (record: any) => {
        return record.id;
      },
    },
    {
      title: <FormattedMessage id="product" />,
      key: 'productName',
      render: (record: any) => {
        return record.productName;
      },
    },
    {
      title: <FormattedMessage id="paymentType" />,
      key: 'paymentType',
      render: (record: any) => {
        return record.paymentType;
      },
    },
    {
      title: <FormattedMessage id="creditLimit" />,
      key: 'creditLimit',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.creditLimit);
      },
    },
    {
      title: <FormattedMessage id="osb" />,
      key: 'osb',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.osb);
      },
    },
    {
      title: <FormattedMessage id="cfPrincipal" />,
      key: 'cfPrincipal',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.cfPrincipal);
      },
    },
    {
      title: <FormattedMessage id="cfInterest" />,
      key: 'cfInterest',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.cfInterest);
      },
    },
    {
      title: <FormattedMessage id="cfFee" />,
      key: 'cfFee',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.cfFee);
      },
    },
    {
      title: <FormattedMessage id="minDue" />,
      key: 'minDue',
      align: 'right',
      render: (record: any) => {
        return satangToBath(record.minDue);
      },
    },
    {
      title: <FormattedMessage id="loan.approveDate" />,
      key: 'approveDate',
      render: (record: any) => {
        return iso8601ToLocalDate(record.approveDate);
      },
    },
    {
      title: <FormattedMessage id="loan.openDate" />,
      key: 'openDate',
      render: (record: any) => {
        return iso8601ToLocalDate(record.openDate);
      },
    },
    {
      title: <FormattedMessage id="loan.lastpayDate" />,
      key: 'lastpayDate',
      render: (record: any) => {
        return iso8601ToLocalDate(record.lastpayDate);
      },
    },
    {
      title: <FormattedMessage id="loan.lastpayAmount" />,
      key: 'lastpayAmount',
      render: (record: any) => {
        return satangToBath(record.lastpayAmount);
      },
    },
    {
      title: <FormattedMessage id="status" />,
      key: 'status',
      fixed: 'right',
      render: (record: any) => {
        return record.status;
      },
    },
    {
      title: <FormattedMessage id="debtSummary.overdue" />,
      key: 'b',
      align: 'right',
      fixed: 'right',
      render: (record: any) => {
        return record.b;
      },
    },
  ];

  return (
    <div>
      <Table
        dataSource={dataSource ? dataSource.map((data: any) => ({ ...data, key: data.id })) : []}
        loading={loading}
        columns={columns}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default DebtSummary;
