import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { push } from 'connected-react-router';
import { Form, Input, Row, Col, Button, notification, Descriptions } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import InputBaht from '../../../../../common/InputBaht';
import styles from '../../../../application.module.scss';
import componentValues from '../../../../../../../services/utilities/ComponentValue';
import { API_URL, patchJson, getJson, showError } from '../../../../../../../helpers/api/api';
import { satangToBath } from '../../../../../../../helpers/format/satangToBath';
import DebtSummary from './DebtSummary';
import { Store } from 'antd/lib/form/interface';
import { ErrorInfo } from '../../../../../../../types/form-types';
import { onFinishFailedHandler } from '../../../../../../../helpers/form';
import { applicationStatusToStage } from '../../../../../../../helpers/application';
import renderIfHasPermissisons from '../../../../../../../layouts/renderIfHasPermissisons';
import { datediff } from '../../../../../../../helpers/date/date';

interface Props {
  application: any;
  projectId: string;
}

const ApproveInfo: React.FC<Props & PropsFromRedux> = ({ application, projectId, push }) => {
  const intl = useIntl();
  const { TextArea } = Input;
  const { citizenId } = application;
  const approveType = ['approving', 'confirming', 'loan_opened'];

  const [validateAnalystAmount, setValidateAnalystAmount] = useState(false);
  const [loanDateDiff, setLoanDateDiff] = useState<any>('');
  const [data, setData] = useState<any>({
    data: {
      loans: [],
      totalOsb: undefined,
      osbDivineSalary: 0,
      osbDivineIncome: 0,
    },
  });

  const approve = approveType.includes(application.status) ? (
    <Form.Item
      label={<FormattedMessage id="approveAmount" />}
      name="approveAmount"
      rules={[
        {
          validator: (_, value) =>
            new Promise((resolve, reject) => {
              const isMaximumCreditLimitSet = !!application.maximumCreditLimit;
              const isValueExceedingLimit =
                value && parseFloat(value) > application.maximumCreditLimit;

              if (!isMaximumCreditLimitSet || !isValueExceedingLimit) {
                resolve();
              } else {
                reject(new Error('Value should be less than the maximum credit limit'));
              }
            }),
        },
      ]}
    >
      <InputBaht />
    </Form.Item>
  ) : null;
  const analystForm = (
    <Form.Item
      label={<FormattedMessage id="analystAmount" />}
      name="analystAmount"
      validateStatus={validateAnalystAmount ? 'warning' : undefined}
      help={validateAnalystAmount ? 'วงเงินเสนออนุมัติ ต้องไม่มากกว่า วงเงินร้องขอ' : undefined}
      rules={[
        {
          validator: (_, value) =>
            new Promise((resolve, reject) => {
              const { requestAmount } = application;

              if (value > requestAmount) {
                setValidateAnalystAmount(true);
                resolve();
              } else {
                setValidateAnalystAmount(false);
                resolve();
              }
            }),
        },
      ]}
    >
      <InputBaht />
    </Form.Item>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getJson(
          `${API_URL}/projects/${projectId}/loans/${citizenId}/debt-summary-table`,
        );
        const { totalOsb, loans } = result.data;
        const { baseSalary, extraIncome } = application.employmentInfo;
        const newBaseSalary = Number(baseSalary || 0);
        const newExtraIncome = Number(extraIncome || 0);
        const sumIncome = newBaseSalary + newExtraIncome;

        result.data.baseSalary = newBaseSalary;
        result.data.extraIncome = newExtraIncome;
        result.data.osbDivineSalary = Number((totalOsb / newBaseSalary) * 100).toFixed(0);
        result.data.osbDivineIncome = Number((totalOsb / sumIncome) * 100).toFixed(0);
        result.data.totalOsb = totalOsb === 0 ? 0 : Number(totalOsb).toFixed(0);
        result.data.totalCreditLimit = loans
          .map(({ creditLimit, status }: { creditLimit: number; status: string }) =>
            status !== 'close' ? creditLimit : 0,
          )
          .reduce(
            (totalCreditLimit: number, creditLimit: number) => totalCreditLimit + creditLimit,
            0,
          );

        if (application.analystAmount > application.requestAmount) setValidateAnalystAmount(true);

        setData(result.data);
        if (loans.length > 0) {
          let lastDate = moment(loans[0].openDate).format('YYYY-MM-DD');
          const nowDate = moment().format('YYYY-MM-DD');
          loans.forEach((loan: any) => {
            const { openDate } = loan;
            if (moment(openDate) > moment(lastDate))
              lastDate = moment(openDate).format('YYYY-MM-DD');
          });
          setLoanDateDiff(datediff(nowDate, lastDate));
        }
      } catch (error) {
        showError(error);
      }
    };
    fetchData();
  }, [projectId, citizenId, application.employmentInfo]);

  const onFinish = async (value: Store) => {
    try {
      await patchJson(`${API_URL}/projects/${projectId}/applications/${application.id}`, value);
      notification.success({
        message: intl.formatMessage({ id: 'application-edit.save-success-message' }),
      });
    } catch (error) {
      showError(error);
    }
  };

  const onFinishFailed = (errorInfo: ErrorInfo) => {
    onFinishFailedHandler(intl, 'common.inputvalidation-failed', errorInfo);
  };

  const stage = applicationStatusToStage(application.status);
  const SaveButton = renderIfHasPermissisons(
    [`applications.${stage}.update`],
    <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">
      <FormattedMessage id="save" />
    </Button>,
  );

  return (
    <div id="ApproveInfo" className={styles.header}>
      <strong className={styles.title}>{<FormattedMessage id="debtSummary" />}</strong>
      <div>
        <DebtSummary dataSource={data.loans} />
      </div>

      <Descriptions bordered>
        <Descriptions.Item label={<FormattedMessage id="debtSummary.totalCreditLimit" />} span={2}>
          {satangToBath(data.totalCreditLimit)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="debtSummary.totalOsb" />} span={2}>
          {satangToBath(data.totalOsb)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="employmentInfo.baseSalary" />} span={2}>
          {satangToBath(data.baseSalary)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="employmentInfo.extraIncome" />} span={2}>
          {satangToBath(data.extraIncome)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="debtSummary.osbDivineSalary" />} span={2}>
          {satangToBath(data.osbDivineSalary)}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="debtSummary.osbDivineIncome" />} span={2}>
          {satangToBath(data.osbDivineIncome)}
        </Descriptions.Item>
        <Descriptions.Item
          label={<FormattedMessage id="debtSummary.timeFromLastOpenLoan" />}
          span={2}
        >
          {loanDateDiff}
        </Descriptions.Item>
      </Descriptions>

      <strong className={styles.title} style={{ marginTop: '20px' }}>
        {<FormattedMessage id="approvalInfo" />}
      </strong>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={application}
      >
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item label={<FormattedMessage id="analystComment" />} name="analystComment">
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Row gutter={componentValues.gutterValue}>
              <Col
                sm={{ span: componentValues.fullColSpan }}
                md={{ span: componentValues.halfColSpan }}
              >
                <Form.Item label={<FormattedMessage id="internalComment" />} name="internalComment">
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
              <Col
                sm={{ span: componentValues.fullColSpan }}
                md={{ span: componentValues.halfColSpan }}
              >
                <Form.Item label={<FormattedMessage id="externalComment" />} name="externalComment">
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            {analystForm}
          </Col>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            {approve}
          </Col>
        </Row>
        <Row gutter={componentValues.gutterValue}>
          <Col
            sm={{ span: componentValues.fullColSpan }}
            md={{ span: componentValues.halfColSpan }}
          >
            <Form.Item label={<FormattedMessage id="requestAmount" />} name="requestAmount">
              <InputBaht disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const connector = connect(null, { push });
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ApproveInfo);
