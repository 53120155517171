import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Row, Col, Button } from 'antd';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import componentValues from '../../../../services/utilities/ComponentValue';
import { store } from '../../../../redux/store';
import { FormattedMessage } from 'react-intl';

interface Prop {
  form: WrappedFormUtils;
  handleSubmitReport: any;
}
const OnlyDateWithLastPostDateReport: React.FC<Prop> = ({ form, handleSubmitReport }) => {
  const { getFieldDecorator } = form;
  const { email } = store.getState().user;
  return (
    <div>
      <Row gutter={componentValues.gutterValue}>
        <Col>
          <Form.Item label={<FormattedMessage id="common.date" />}>
            {getFieldDecorator('asOfDate', {
              rules: [
                {
                  required: true,
                  message: 'please select asOfDate',
                },
              ],
            })(<DatePicker />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={componentValues.gutterValue}>
        <Col>
          <Form.Item label={<FormattedMessage id="report.lastPostDate" />}>
            {getFieldDecorator('lastPostDate', {
              rules: [
                {
                  required: true,
                  message: 'please select lastPostDate',
                },
              ],
            })(<DatePicker />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button style={{ marginLeft: 8 }} type="primary" onClick={handleSubmitReport}>
            {<FormattedMessage id="comon.sendTo" />}
            &nbsp;
            {`${email}`}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default OnlyDateWithLastPostDateReport;
