import React, { FC, useState, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckCircleFilled, CloseCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Table, Modal, Divider, Select, Popconfirm, message } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { TablePaginationConfig } from 'antd/lib/table';
import { Store } from 'antd/lib/form/interface';
import { getFullName } from './DefaultTable';
import {
  postJson,
  patchJson,
  API_URL,
  deleteJson,
  showError,
} from '../../../../../helpers/api/api';
import { iso8601ToLocalDate } from '../../../../../helpers/date/date';
import { Application } from '../../../../../types/application-types';
import { UserState } from '../../../../../redux/user/user-duck';
import { satangToBath } from '../../../../../helpers/format/satangToBath';
import InputBaht from '../../../common/InputBaht';
import DateOnlyPicker from '../../../common/DateOnlyPicker';
import renderIfHasPermissions from '../../../../../layouts/renderIfHasPermissisons';

interface ModalProps {
  visible: boolean;
  projectId: string;
  application: Application;
  onCancel: () => void;
  reloadData: () => void;
}
const ConfirmModal: FC<ModalProps> = ({
  visible,
  projectId,
  application,
  onCancel,
  reloadData,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { firstDisburseAmount, approveAmount, paymentType } = application;
  // eslint-disable-next-line no-underscore-dangle
  let _firstDisburseAmount = 0;
  if (paymentType === 'installment') {
    _firstDisburseAmount = Number(approveAmount);
  } else if (paymentType === 'revolving') {
    _firstDisburseAmount =
      firstDisburseAmount !== null
        ? Math.min(Number(firstDisburseAmount), Number(approveAmount))
        : Number(approveAmount);
  }
  form.setFieldsValue({ firstDisburseAmount: _firstDisburseAmount });
  const onFinish = async (values: Store) => {
    try {
      const { firstDisburseAmount } = values;
      const { maximumCreditLimit } = application;
      if (
        maximumCreditLimit &&
        firstDisburseAmount > Number(maximumCreditLimit) &&
        Number(maximumCreditLimit) > 0
      ) {
        message.error(`Maximum CreditLimit: ${satangToBath(Number(maximumCreditLimit))}`);
      } else {
        await postJson(`${API_URL}/projects/${projectId}/loans/application-to-loan`, {
          firstDisburseAmount: values.firstDisburseAmount,
          openDate: values.openDate,
          applications: [{ id: application.id }],
        });
        onCancel();
        reloadData();
      }
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form.submit();
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={intl.formatMessage({ id: 'openDate' })}
          name="openDate"
          rules={[{ required: true }]}
        >
          <DateOnlyPicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'firstDisburseAmount' })}
          name="firstDisburseAmount"
          rules={[
            {
              type: 'number',
              max: Number(approveAmount),
              required: true,
              message: intl.formatMessage(
                { id: 'validatemsg.inputbaht.max-value-exceed' },
                {
                  name: `'firstDisburseAmount'`,
                  maxValue: intl.formatNumber(Number(approveAmount) / 100),
                },
              ),
            },
          ]}
        >
          <InputBaht disabled={paymentType === 'installment'} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const RejectModal: FC<ModalProps> = ({ visible, projectId, application, onCancel, reloadData }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { Option, OptGroup } = Select;
  const rejectOptions = [
    {
      text: 'ข้อมูลประวัติการชำระหนี้/ภาระหนี้',
      objects: [
        {
          value: 'A01',
          text: 'ประวัติเครดิตต่ำการเกณฑ์ที่กำหนด',
        },
        {
          value: 'A02',
          text: 'ถือบัตรเกินจำนวนที่กำหนด (มากกว่า 3 สถาบัน)',
        },
        {
          value: 'A03',
          text: 'ภาระหนี้สูง',
        },
        {
          value: 'A04',
          text: 'ไม่พบข้อมูลอ้างอิงใน NCB และเงินหมุนเวียนในบัญชีคงเหลือต่ำ',
        },
        {
          value: 'A05',
          text: 'เพิ่งได้รับอนุมัติสินเชื่อใหม่จากสถาบันอื่น',
        },
        {
          value: 'A06',
          text: 'ประวัติการขอสินเชื่อใหม่ย้อนหลัง 3 เดือน มีประวัติขอมากกว่า 5 สถาบัน',
        },
        {
          value: 'A07',
          text: 'Credit Scoring ต่ำกว่าเกณฑ์ที่กำหนด',
        },
      ],
    },
    {
      text: 'ข้อมูลส่วนบุคคล',
      objects: [
        {
          value: 'B01',
          text: 'อายุต่ำกว่าหรือสูงกว่าเกณฑ์ที่กำหนด',
        },
        {
          value: 'B02',
          text: 'ผู้สมัครไม่ใช่ผู้มีสัญชาติไทย',
        },
      ],
    },
    {
      text: 'ข้อมูลการทำงาน',
      objects: [
        {
          value: 'D01',
          text: 'อายุงานต่ำกว่าเกณฑ์ที่กำหนด (6 เดือนขึ้นไป)',
        },
        {
          value: 'D02',
          text: 'เปิดกิจการต่ำกว่าเกณฑ์ที่กำหนด (1 ปีขึ้นไป)',
        },
        {
          value: 'D03',
          text: 'ลูกค้าลาออกจากงาน/ไม่ได้ทำงาน',
        },
        {
          value: 'D04',
          text: 'อาชีพเสี่ยง หน้าที่การงานไม่มั่นคง',
        },
      ],
    },
    {
      text: 'ข้อมูลรายได้',
      objects: [
        {
          value: 'E01',
          text: 'รายได้ต่ำกว่าเกณฑ์ที่กำหนด',
        },
        {
          value: 'E02',
          text: 'รายได้ไม่ตรงกับสลิปเงินเดือน/หนังสือรับรองรายได้',
        },
      ],
    },
    {
      text: 'ข้อมูลการติดต่อลูกค้า/ที่ทำงาน',
      objects: [
        {
          value: 'F01',
          text: 'ติดต่อที่ทำงานไม่ได้/เช็คข้อมูลกับฝ่ายบุคคลไม่ได้',
        },
        {
          value: 'F02',
          text: 'ไม่สามารถติดต่อผู้สมัครได้/ไม่ติดต่อกลับภายในระยะเวลาที่กำหนด',
        },
        {
          value: 'F03',
          text: 'ลูกค้าขอยกเลิกกการสมัคร',
        },
      ],
    },
    {
      text: 'เอกสารใบสมัคร/เอกสารประกอบการสมัคร',
      objects: [
        {
          value: 'G01',
          text: 'ไม่ได้ลงลายมือชื่อในใบสมัคร/ลายมือชื่อไม่ตรงกัน',
        },
        {
          value: 'G02',
          text: 'เอกสารน่าสงสัย/ยังไม่ยืนยันการปลอมแปลง',
        },
        {
          value: 'G03',
          text: 'เอกสารไม่สมบูรณ์/เอกสารเพิ่มเติมไม่ส่งมาภายในระยะเวลาที่กำหนด',
        },
        {
          value: 'G04',
          text: 'ติด Blacklist ของบริษัทฯ',
        },

        {
          value: 'T01',
          text: 'ไม่ผ่านเกณฑ์การพิจารณาเบื้องต้น',
        },
      ],
    },
  ];
  const onFinish = async (value: Store) => {
    try {
      await patchJson(`${API_URL}/projects/${projectId}/applications/${application.id}/status`, {
        status: 'reject',
        rejectReason: value.rejectReason,
        rejectDate: value.rejectDate,
      });
      onCancel();
      reloadData();
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form.submit();
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label={intl.formatMessage({ id: 'rejectDate' })}
          name="rejectDate"
          rules={[{ required: true }]}
        >
          <DateOnlyPicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'rejectReason' })} name="rejectReason">
          <Select placeholder="Please select status">
            {rejectOptions.map(header => (
              <OptGroup label={header.text} key={header.text}>
                {header.objects.map(object => (
                  <Option value={object.value} key={object.value}>
                    {object.value}: {object.text}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

interface Props {
  dataSource: Application[];
  loading: boolean;
  onChange: (pagination: TablePaginationConfig) => void;
  paginations?: TablePaginationConfig;
  reloadData: () => void;
}

const ConfirmTable: React.FC<Props & PropsFromRedux> = ({
  dataSource,
  loading,
  onChange,
  paginations,
  reloadData,
  projectId,
}) => {
  const intl = useIntl();
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const [currentApplication, setCurrentApplication] = useState<any>({});
  const DeleteButton: FC<any> = ({ applicationId }) => {
    return (
      <span>
        <Popconfirm
          title={<FormattedMessage id="common.are-you-sure" />}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          cancelText={<FormattedMessage id="no" />}
          okText={<FormattedMessage id="yes" />}
          okButtonProps={{ danger: true }}
          onConfirm={async () => {
            try {
              await deleteJson(`${API_URL}/projects/${projectId}/applications/${applicationId}`);
              reloadData();
            } catch (error) {
              showError(error);
            }
          }}
          onCancel={e => e && e.stopPropagation()}
        >
          <a href="#" onClick={e => e.stopPropagation()}>
            <i className="icmn-bin" />
          </a>
        </Popconfirm>
      </span>
    );
  };

  const columns: ColumnType<Application>[] = [
    {
      title: intl.formatMessage({ id: 'application.id' }),
      fixed: 'left',
      dataIndex: 'id',
      width: '96px',
      render: (id: string) => {
        return (
          <Link className="utils__link--blue utils__link--underlined" to={`applications/${id}`}>
            {id}
          </Link>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'full-name' }),
      dataIndex: 'name',
      width: '192px',
      // eslint-disable-next-line
      render: (name: string, row: Application) => {
        return getFullName(row);
      },
    },
    { title: intl.formatMessage({ id: 'citizenId' }), width: '136px', dataIndex: 'citizenId' },
    { title: intl.formatMessage({ id: 'product' }), dataIndex: 'productName' },
    {
      title: intl.formatMessage({ id: 'requestAmount' }),
      align: 'right',
      width: '120px',
      dataIndex: 'requestAmount',
      render: (amount: number) => {
        return satangToBath(amount);
      },
    },
    {
      title: intl.formatMessage({ id: 'approveAmount' }),
      align: 'right',
      width: '120px',
      dataIndex: 'approveAmount',
      render: (amount: number) => {
        return satangToBath(amount);
      },
    },
    { title: intl.formatMessage({ id: 'mobileNo' }), dataIndex: 'mobileNo' },
    {
      title: intl.formatMessage({ id: 'createDate' }),
      width: '96px',
      dataIndex: 'createDate',
      render: (createDate: string) => {
        return iso8601ToLocalDate(createDate);
      },
    },
    {
      title: intl.formatMessage({ id: 'lastModifyDate' }),
      width: '96px',
      dataIndex: 'lastModifyDate',
      render: (lastModifyDate: string) => {
        return iso8601ToLocalDate(lastModifyDate);
      },
    },
    {
      title: intl.formatMessage({ id: 'lastModifyBy' }),
      width: '152px',
      dataIndex: 'lastModifyBy',
    },
    {
      fixed: 'right',
      render: (value: Application) => {
        const ActionButton = renderIfHasPermissions(
          'loans.*.create',
          <Fragment>
            <CheckCircleFilled
              style={{
                color: '#008dff',
                fontSize: '20px',
              }}
              onClick={() => {
                setCurrentApplication(value);
                setConfirmModalVisible(true);
              }}
            />
            <Divider type="vertical" />
            <CloseCircleFilled
              style={{
                color: '#f81c22',
                fontSize: '20px',
              }}
              onClick={e => {
                setCurrentApplication(value);
                setRejectModalVisible(true);
              }}
            />
          </Fragment>,
        );
        return <ActionButton />;
      },
    },
    {
      width: '50px',
      fixed: 'right',
      render: (value: any) => {
        const DeleteIfHasPermission = renderIfHasPermissions(
          'application.*.delete',
          <DeleteButton applicationId={value.id} />,
        );
        return <DeleteIfHasPermission />;
      },
    },
  ];

  return (
    <Fragment>
      <Table
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        onChange={onChange}
        pagination={paginations}
        scroll={{ x: 'max-content' }}
      />
      <ConfirmModal
        visible={confirmModalVisible}
        projectId={projectId}
        application={currentApplication}
        onCancel={() => {
          setConfirmModalVisible(false);
        }}
        reloadData={reloadData}
      />
      <RejectModal
        visible={rejectModalVisible}
        projectId={projectId}
        application={currentApplication}
        onCancel={() => {
          setRejectModalVisible(false);
        }}
        reloadData={reloadData}
      />
    </Fragment>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => ({ projectId: user.currentProjectId });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConfirmTable);
